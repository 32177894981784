import Fuse from 'fuse.js'

import getClientSideGroup from 'bff/handlers/groups/slug/client'
import errorHandler from 'helpers/error-handler'

import t from './actionTypes'
import groups from './default-groups'

const fuseOptions = {
  includeScore: true,
  threshold: 0.2,
  distance: 50,
  ignoreLocation: true // NOTE: Will match search string with product/group anywhere in match string https://fusejs.io/api/options.html#ignorelocation
}

const groupFuseOptions = {
  ...fuseOptions,
  keys: [
    'description',
    {
      name: 'name',
      weight: 3
    },
    {
      name: 'displayName',
      weight: 2
    },
    {
      name: 'misspellings',
      weight: 3
    }
  ]
}

const productFuseOptions = {
  ...fuseOptions,
  distance: 100,
  keys: [
    {
      name: 'subtype.name',
      weight: 5
    },
    {
      name: 'type.name',
      weight: 4
    },
    {
      name: 'name',
      weight: 3
    },
    {
      name: 'brand.name',
      weight: 2
    },
    'description'
  ]
}

export function setFuseProducts(products) {
  // Fuse only accepts list of objects
  const productList = Array.isArray(products) ? products : Object.values(products)
  const productIndex = Fuse.createIndex(productFuseOptions.keys, productList)
  const fuse = new Fuse(productList, productFuseOptions, productIndex)

  return {
    type: t.SET_FUSE_PRODUCT_SEARCH,
    payload: fuse
  }
}

export function setFuseGroups() {
  const groupIndex = Fuse.createIndex(groupFuseOptions.keys, groups)
  const fuse = new Fuse(groups, groupFuseOptions, groupIndex)

  return {
    type: t.SET_FUSE_GROUP_SEARCH,
    payload: fuse
  }
}

export function closeSearchDrawer() {
  return {
    type: t.CLOSE_SEARCH_DRAWER
  }
}

export function openSearchDrawer() {
  return {
    type: t.OPEN_SEARCH_DRAWER
  }
}

export function setSearchLoading(loading) {
  return {
    type: t.REQUEST_FEATURED_PRODUCTS_LOADING,
    loading
  }
}

export function setSearchError() {
  return {
    type: t.REQUEST_FEATURED_PRODUCTS_ERROR,
    error: true,
    loading: false
  }
}

export function toggleSearchDrawer() {
  return (dispatch, getState) => {
    const searchDrawerState = getState().search.isSearchDrawerOpen

    if (searchDrawerState) {
      dispatch(closeSearchDrawer())
    } else {
      dispatch(openSearchDrawer())
    }
  }
}

export function requestFeaturedProducts(slug, locationId) {
  return async (dispatch, getState) => {
    const {
      location: { activeLocation }
    } = getState()
    const body = {
      slug
    }

    dispatch(setSearchLoading(true))

    const placeId = locationId || activeLocation.id
    if (placeId) {
      body.placeId = placeId
    }

    const { err, data: group } = await getClientSideGroup(body)

    if (err) {
      dispatch(setSearchError())
      return errorHandler(new Error(`Error requesting group: ${err.message}`))
    }

    const searchProducts = group.products?.slice(0, 4)
    dispatch(setSearchLoading(false))
    dispatch({
      type: t.SET_FEATURED_PRODUCTS,
      payload: searchProducts
    })
  }
}
